<template>
  <div
    v-if="'-' !== payout.reportRunUrl"
    :class="$store.getters['layout/getInnerWidth'] >= 991 ? 'justify-content-center' : 'justify-content-left'"
    class="d-flex"
  >
   <span
     class="text-blue-light"
     @click="showBill"
   >
     <i class="pointer fa fa-file"></i>
   </span>
  </div>
</template>
<script>
import {getPayoutReport} from "@api/doinsport/services/stripe/payout/payout.api";

export default {
  props: {
    payout: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    showBill() {
      if (null !== this.payout.reportRunUrl) {
        getPayoutReport(this.payout.id)
          .then((response) => {
            this.$downloadCSV(response.data, this.payout.constructor.name);
          })
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.fa-file {
  font-size: 20px;
}

.not-allowed {
  cursor: not-allowed;
}
</style>
